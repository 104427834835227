import { defineMessages } from "react-intl";
export default defineMessages({
  invalid_mail : {
    id: "a2vote.user.invalid.mail",
    defaultMessage: "Please enter a valid email address",
  },
  hello : {
    id: "a2vote.user.hello",
    defaultMessage: "Hello ",
  },
  mail : {
    id: "a2vote.user.mail",
    defaultMessage: "Please enter your email address to validate your account",
  },
  confirm : {
    id: "a2vote.user.confirm",
    defaultMessage: "Confirm and send",
  },
});
