//React
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// tools
import { decryptData } from "utils/Encryption";
import { validateEmail } from "utils/mailValidation";
import styled from "styled-components";
// Actions
import { sendMail } from "actions/login";
// Components
import Button from "components/Button/Button";
// i18n
import { FormattedMessage } from "react-intl";
import messages from "./messages";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 100%;
`;

const Hello = styled.span`
  font-size: 1.5rem;
`;

const Text = styled.span`
  margin: 8px 0px;
`;

const MailInput = styled.input`
  width: 80%;
  margin: 8px 0px;
`;

const ErrorMessage = styled.span`
  color: red;
`

const RegistrationMail = () => {
  // Local state
  const [mail, setMail] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // Bind State
  const { userFront } = useSelector((state) => state.user);
  // Get userFront data
  const userData = decryptData(userFront?.data);
  // Bind Actions
  const dispatch = useDispatch();
  const send = (mail) => dispatch(sendMail(mail));

  const sendMailAction = () => {
    if (mail && validateEmail(mail)) {
      send(mail);
      setError(false);
    }
    else{
      setError(true);
      setErrorMessage(<FormattedMessage {...messages.invalid_mail} />);
    }
  };

  return (
    <Container>
      <Hello>
      <FormattedMessage {...messages.hello} /> {userData.firstname} {userData.lastname} !
      </Hello>
      <Text>
      <FormattedMessage {...messages.mail} /> :
      </Text>
      <MailInput value={mail} onChange={(e) => setMail(e.target.value)} />
      {
        error && <ErrorMessage>{errorMessage}</ErrorMessage>
      }
      <Button action={() => sendMailAction()}><FormattedMessage {...messages.confirm} /></Button>
    </Container>
  );
};

export default RegistrationMail;
