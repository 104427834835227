// React
import React from "react";
import RegistrationMail from "../../containers/User/RegistrationMail";
import UserLogged from "../../containers/userLogged";

const Mailregistration = () => {
  return (
    <UserLogged>
      <RegistrationMail />
    </UserLogged>
  );
};

export default Mailregistration;
