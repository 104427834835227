//React
import React from "react";
//PropTypes
import PropTypes from "prop-types";
//Style
import styled from "styled-components";
import theme from "theme";

const ButtonStyled = styled.button`
  background-color: ${theme.ui.blue};
  color: ${theme.white};
  border: none;
  border-radius: 5px;
  padding: 8px;
  font-size: ${props => props.textSize || "16px"};

  &:hover {
    cursor: pointer;
  }

  ${props => (props.green || props.textColor == "darkgray") && `
    color: ${theme.darkgray};
  `}

  ${props => (props.green || props.textColor == "blue") && `
    color: ${theme.ui.blue};
  `}

  ${props => (props.green || props.backgroundColor == "green") && `
    background-color: ${theme.lightgreen};
  `}

  ${props => props.backgroundColor == "red" && `
    background-color: ${theme.lightred};
  `}

  ${props => props.backgroundColor == "lightorange" && `
    background-color: ${theme.lightorange};
  `}

  ${props => props.backgroundColor == "orange" && `
    background-color: ${theme.orange};
  `}

  ${props => props.backgroundColor == "aliceblue" && `
    background-color: ${theme.aliceblue};
  `}

  ${props=>props.disable && `
    background: ${theme.ui.grey};
    &:hover {
      cursor: not-allowed;
    }
  `}

  ${props => props.textColor == "orange" && `
    color: ${theme.orange};
  `}

  ${props => props.textColor == "darkgray" && `
    color: ${theme.darkgray};
  `}
`;

export const ButtonWithMargin = styled(ButtonStyled)`
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
`;

const Button = ({ children, action, onClick, backgroundColor, textColor, type, disable, green, textSize}) => {
  return (
    <ButtonStyled
      backgroundColor={backgroundColor}
      onClick={!disable ? (onClick || action) : undefined}
      type={type}
      textColor={textColor}
      disable={disable}
      green={green}
      textSize={textSize}
    >
      {children}
    </ButtonStyled>
  );
};

Button.propTypes = {
  action: PropTypes.func,
  backgroundColor: PropTypes.string,
  children: PropTypes.any,
  type: PropTypes.string,
  textColor: PropTypes.string,
};

export default Button;